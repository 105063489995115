import { Button, Input, Modal, Switch, Typography, message } from "antd";
import { useContext, useEffect, useState } from "react";
import "../Users/users.css";
import api from "../../../api";
import { AuthContext } from "../../../contexts/AuthContext";

function ClientsModal(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { authInfo } = useContext(AuthContext);
  const { dataUser } = authInfo;

  const [values, setValues] = useState({
    name: "",
    email: "",
    active: false,
    password: "",
    confirmPassword: "",
    mercadoPagoToken: "",
    expirationDate: "",
  });
  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (props.user) {
      setValues({
        name: props.user.name,
        email: props.user.email,
        active: props.user.active,
        password: "",
        confirmPassword: "",
        mercadoPagoToken: props.user.mercadoPagoToken,
        expirationDate: props.user.expirationDate,
      });
    }
  }, [isModalOpen]);

  const handleOk = () => {
    if (values.password && values.password.length < 6) {
      message.error("A senha tem que ter pelo menos 6 dígitos");
      return;
    }
    if (props.edit) {
      if (values.password === values.confirmPassword) {
        api
          .put(`/client/update/${props.user.id}`, {
            name: values.name,
            email: values.email,
            password: values.password,
            active: values.active,
            mercadoPagoToken: values.mercadoPagoToken,
            expirationDate: new Date(values.expirationDate),
            personId: dataUser.id,
          })
          .then((res) => {
            setIsModalOpen(false);
            props.onUpdate();
            message.success("Operação concluída!");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        message.error("As senhas precisam ser iguais!");
      }
    } else {
      if (values.password === values.confirmPassword) {
        api
          .post("/client/create", {
            name: values.name,
            email: values.email,
            password: values.password,
            active: values.active,
            mercadoPagoToken: values.mercadoPagoToken,
            expirationDate: new Date(values.expirationDate),
            personId: dataUser.id,
          })
          .then((res) => {
            setIsModalOpen(false);
            props.onUpdate();
            message.success("Operação concluída!");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        message.error("As senhas precisam ser iguais!");
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {props.edit ? "Editar" : "Adicionar"}
      </Button>
      <Modal
        title={`${props.edit ? "Editar" : "Adicionar"} ${
          props.user?.name || ""
        }`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="userModal">
          <div>
            <Typography.Title level={5}>Ativo?</Typography.Title>
            <Switch
              checked={values.active}
              onChange={() => setValues({ ...values, active: !values.active })}
            />
          </div>
          <div>
            <Typography.Title level={5}>Nome de usuário</Typography.Title>
            <Input
              name="name"
              value={values.name}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
            />
          </div>
          <div>
            <Typography.Title level={5}>E-mail</Typography.Title>
            <Input
              name="email"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
            />
          </div>
          <div>
            <Typography.Title level={5}>Token do Mercado Pago</Typography.Title>
            <Input
              name="mercadoPagoToken"
              value={values.mercadoPagoToken}
              onChange={(e) =>
                setValues({ ...values, mercadoPagoToken: e.target.value })
              }
            />
          </div>
          <div>
            <Typography.Title level={5}>Data de Expiração</Typography.Title>
            <Input
              name="expirationDate"
              type="date"
              onChange={(e) => {
                setValues({ ...values, expirationDate: e.target.value });
              }}
            />
          </div>
          <div>
            <Typography.Title level={5}>Senha</Typography.Title>
            <Input
              name="password"
              type="password"
              onChange={(e) =>
                setValues({ ...values, password: e.target.value })
              }
            />
          </div>
          <div>
            <Typography.Title level={5}>Confirmar senha</Typography.Title>
            <Input
              type="password"
              onChange={(e) =>
                setValues({ ...values, confirmPassword: e.target.value })
              }
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ClientsModal;
