import Cookies from "js-cookie";
import { createContext, useEffect, useState } from "react";
import api from "../api";
import { useNavigate } from "react-router-dom";
import * as links from "../utils/links";

const authDefault = {
  isAuthenticated: false,
  dataUser: null,
};

export const AuthContext = createContext({
  authInfo: authDefault,
  setDataUser: (dataUser) => {},
  loading: false,
  notiMessage: "",
  setNotiMessage: () => {},
});

const AuthContextProvider = ({ children }) => {
  const [authInfo, setAuthInfo] = useState(authDefault);
  const [loading, setLoading] = useState(true);
  const [notiMessage, setNotiMessage] = useState(null);

  // const [notiMessageInfo, setNotiMessageInfo] = useState(null)
  useEffect(() => {
    const token = Cookies.get("token");

    if (token) {
      api
        .post(`/user/refresh`)
        .then((res) => {
          Cookies.set("token", res.data.newToken, { expires: 1 });
          setAuthInfo({
            isAuthenticated: true,
            dataUser: res.data.user,
          });
        })
        .catch((err) => {
          Cookies.remove("token");

          setAuthInfo({
            isAuthenticated: false,
            dataUser: null,
          });
        });

      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  const setDataUser = (dataUser) => {
    setAuthInfo({
      isAuthenticated: false,
      dataUser: dataUser,
    });
  };

  const authContextData = {
    authInfo,
    loading,
    setDataUser,
    notiMessage,
    setNotiMessage,
    // notiMessageInfo,
    // setNotiMessageInfo
  };

  return (
    <AuthContext.Provider value={authContextData}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
