import { useEffect, useState } from "react";
import "../Users/users.css";
import api from "../../../api";
import UserModal from "./UserModal";
import { Button, Popconfirm, message } from "antd";

const Users = () => {
  const [users, setUsers] = useState([]);

  const handleGetUsers = () => {
    api
      .get("/user/list")
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetUsers();
  }, []);

  const confirm = (e) => {
    api
      .delete(`/user/delete/${e}`)
      .then((res) => {
        message.success("Operação concluída!");
        handleGetUsers();
      })
      .catch((err) => {
        message.error("Ocorreu um erro ao excluir o item!");
      });
  };
  const cancel = (e) => {
    message.error("Operação cancelada!");
  };

  return (
    <div>
      <div className="userFlex">
        <div className="Dashboard_staBlockTitle">Usuários</div>
        <UserModal onUpdate={handleGetUsers} />
      </div>

      <div className="userboxContainer">
        {users.map((item) => {
          return (
            <div className="userbox" key={item.id}>
              <div>
                <div>
                  <b>Nome: </b>
                  {item.name}
                </div>
                <div>
                  <b>Email: </b> {item.email}
                </div>
                <div>
                  <b>Criado em:</b>{" "}
                  {new Date(item.inclusionDate).toLocaleString("pt-BR")}
                </div>
                <div>
                  <b>Ultimo Acesso:</b>{" "}
                  {new Date(item.lastAccess).toLocaleString("pt-BR")}
                </div>
              </div>
              <div className="userboxButtons">
                <UserModal user={item} edit onUpdate={handleGetUsers} />
                <Popconfirm
                  title="Excluir usuario?"
                  description="Tem certeza que deseja excluir este item?"
                  onConfirm={() => confirm(item.id)}
                  onCancel={cancel}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Button danger>Delete</Button>
                </Popconfirm>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Users;
