import { useContext, useEffect, useState } from "react";
import "../Users/users.css";
import api from "../../../api";
import UserModal from "./ClientsModal";
import { Button, Popconfirm, message } from "antd";
import { AuthContext } from "../../../contexts/AuthContext";
import ClientsModal from "./ClientsModal";

const Client = () => {
  const [clients, setClients] = useState([]);
  const { authInfo } = useContext(AuthContext);
  const { dataUser } = authInfo;
  const handleGet = () => {
    api
      .get(`/client/list/${dataUser.id}`)
      .then((res) => {
        setClients(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGet();
  }, [dataUser]);

  const confirm = (e) => {
    api
      .delete(`/client/delete/${e}`)
      .then((res) => {
        message.success("Operação concluída!");
        handleGet();
      })
      .catch((err) => {
        message.error("Ocorreu um erro ao excluir o item!");
      });
  };
  const cancel = (e) => {
    message.error("Operação cancelada!");
  };

  return (
    <div>
      <div className="userFlex">
        <div className="Dashboard_staBlockTitle">Clientes</div>
        <ClientsModal onUpdate={handleGet} />
      </div>

      <div className="userboxContainer">
        {clients.map((item) => {
          return (
            <div className="userbox" key={item.id}>
              <div>
                <div>
                  <b>Nome: </b>
                  {item.name}
                </div>
                <div>
                  <b>Email: </b> {item.email}
                </div>
                <div>
                  <b>Criado em:</b>{" "}
                  {new Date(item.inclusionDate).toLocaleString("pt-BR")}
                </div>
                <div>
                  <b>Ultimo Acesso:</b>{" "}
                  {new Date(item.lastAccess).toLocaleString("pt-BR")}
                </div>
                <div>
                  <b>Data de Expiração:</b>{" "}
                  {item.expirationDate
                    ? new Date(item.expirationDate).toLocaleString("pt-BR")
                    : "Não definido"}
                </div>
                <div>
                  <b>Ativo: </b> {item.active ? "Sim" : "Não"}
                </div>
                <div>
                  <b>ID: </b> {item.id}
                </div>
              </div>
              <div className="userboxButtons">
                <ClientsModal user={item} edit onUpdate={handleGet} />
                <Popconfirm
                  title="Excluir usuario?"
                  description="Tem certeza que deseja excluir este item?"
                  onConfirm={() => confirm(item.id)}
                  onCancel={cancel}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Button danger>Delete</Button>
                </Popconfirm>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Client;
