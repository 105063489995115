import { Button, Input, Modal, Switch, Typography, message } from "antd";
import { useEffect, useState } from "react";
import "../Users/users.css";
import api from "../../../api";

function UserModal(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    isAdmin: false,
    password: "",
    confirmPassword: "",
  });
  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (props.user) {
      setValues({
        name: props.user.name,
        email: props.user.email,
        isAdmin: props.user.isAdmin,
        password: "",
        confirmPassword: "",
      });
    }
  }, [isModalOpen]);

  const handleOk = () => {
    if (values.password && values.password.length < 6) {
      message.error("A senha tem que ter pelo menos 6 dígitos");
      return;
    }
    if (props.edit) {
      if (values.password === values.confirmPassword) {
        api
          .put(`/user/update/${props.user.id}`, {
            name: values.name,
            email: values.email,
            password: values.password,
            isAdmin: values.isAdmin,
          })
          .then((res) => {
            setIsModalOpen(false);
            props.onUpdate();
            message.success("Operação concluída!");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        message.error("As senhas precisam ser iguais!");
      }
    } else {
      if (values.password === values.confirmPassword) {
        api
          .post("/user/create", {
            name: values.name,
            email: values.email,
            password: values.password,
            isAdmin: values.isAdmin,
          })
          .then((res) => {
            setIsModalOpen(false);
            props.onUpdate();
            message.success("Operação concluída!");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        message.error("As senhas precisam ser iguais!");
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {props.edit ? "Editar" : "Adicionar"}
      </Button>
      <Modal
        title={`${props.edit ? "Editar" : "Adicionar"} ${
          props.user?.name || ""
        }`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="userModal">
          <div>
            <Typography.Title level={5}>Administrador?</Typography.Title>
            <Switch
              checked={values.isAdmin}
              onChange={() =>
                setValues({ ...values, isAdmin: !values.isAdmin })
              }
            />
          </div>
          <div>
            <Typography.Title level={5}>Nome de usuário</Typography.Title>
            <Input
              name="name"
              value={values.name}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
            />
          </div>
          <div>
            <Typography.Title level={5}>E-mail</Typography.Title>
            <Input
              name="email"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
            />
          </div>
          <div>
            <Typography.Title level={5}>Senha</Typography.Title>
            <Input
              name="password"
              type="password"
              onChange={(e) =>
                setValues({ ...values, password: e.target.value })
              }
            />
          </div>
          <div>
            <Typography.Title level={5}>Confirmar senha</Typography.Title>
            <Input
              type="password"
              onChange={(e) =>
                setValues({ ...values, confirmPassword: e.target.value })
              }
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UserModal;
