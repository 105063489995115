export const SIGNIN = "/";
// export const SIGNIN_FORNECEDOR = '/signin-fornecedor';
export const SIGNUP_CANAL = "/signup-canal";
export const SIGNUP_FORNECEDOR = "/signup-fornecedor";
export const FORGOTPASSWORD = "/forgot-password";
export const RESETPASSWORD = "/reset-password/:token";
export const ACTIVEUSER = "/active-user/:token";

export const DASHBOARD_CANAL = "/dashboard-canal";
export const CANAL_EDIT_PROFILE = "/canal-edit-profile";
export const CANAL_SEARCH_FORNECEDORES = "/canal-search-fornecedores";
export const CANAL_FAVORITES = "/canal-favorites";
export const CANAL_SETTINGS = "/canal-settings";
export const CANAL_BUY_PREMIUM = "/canal-buy-premium";
export const CANAL_FAVORITES_PREMIUM = "/canal-favorites-premium";
export const CANAL_MY_FITS = "/canal-my-fits";

export const DASHBOARD_FORNECEDOR = "/dashboard-maquinas";
export const FORNECEDOR_EDIT_PROFILE = "/fornecedor-edit-profile";
export const FORNECEDOR_SEARCH_CANAIS = "/pagamentos";
export const FORNECEDOR_FAVORITES = "/fornecedor-favorites";
export const FORNECEDOR_SETTINGS = "/fornecedor-settings";
export const FORNECEDOR_BUY_PREMIUM = "/fornecedor-buy-premium";
export const FORNECEDOR_NOTI = "/fornecedor-notifications";
export const FORNECEDOR_MY_FITS = "/my-fits";
export const FORNECEDOR_FAVORITES_PREMIUM = "/fornecedor-favorites-premium";
export const ADD_USER = "/users";
export const ADD_MACHINE = "/create-machine";
export const ADD_CLIENT = "/clients";
// export const MAIN = '/';
