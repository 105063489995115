import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { Navigate } from "react-router-dom";
import LoadingAction from "../themes/LoadingAction/LoadingAction";
import * as links from "../utils/links";

function PersonRoute({ children, ...rest }) {
  const { authInfo, loading } = useContext(AuthContext);
  const { isAuthenticated } = authInfo;
  if (loading) {
    return <LoadingAction />;
  }
  if (
    (isAuthenticated && authInfo.dataUser.type === "person") ||
    (isAuthenticated && authInfo.dataUser.isAdmin)
  ) {
    return children;
  }
  return (
    <center
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      Usuário sem permissão de acesso a esta página
    </center>
  );
}

export default PersonRoute;
