import {
  Button,
  Input,
  Modal,
  Select,
  Switch,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import "../Users/users.css";
import api from "../../../api";
import TextArea from "antd/es/input/TextArea";

function UserModal(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [values, setValues] = useState({
    name: "",
    description: "",
    store_id: "",
    personId: "",
    clientId: "",
    address: "",
  });
  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (values.personId) {
      api
        .get(`/client/list/${values.personId}`)
        .then((res) => setClients(res.data))
        .catch((err) => console.log(err));
    }
  }, [values.personId]);

  const handleGetUsers = async () => {
    try {
      const users = await api.get("/user/list");
      setUsers(users.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetUsers();
    if (props.machine) {
      setValues({
        name: props.machine.name,
        description: props.machine.description,
        store_id: props.machine.store_id,
        personId: props.machine.personId,
        clientId: props.machine.clientId,
        address: props.machine.address,
      });
    }
  }, [isModalOpen]);

  const handleOk = () => {
    if (values.password && values.password.length < 6) {
      message.error("A senha tem que ter pelo menos 6 dígitos");
      return;
    }
    if (props.edit) {
      if (values.password === values.confirmPassword) {
        api
          .put(`/machine/update/${props.machine.id}`, {
            name: values.name,
            description: values.description,
            store_id: values.store_id,
            personId: values.personId,
            clientId: values.clientId,
            address: values.address,
          })
          .then((res) => {
            setIsModalOpen(false);
            props.onUpdate();
            message.success("Operação concluída!");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        message.error("As senhas precisam ser iguais!");
      }
    } else {
      if (values.password === values.confirmPassword) {
        api
          .post("/machine/create", {
            name: values.name,
            description: values.description,
            store_id: values.store_id,
            personId: values.personId,
            clientId: values.clientId,
            address: values.address,
          })
          .then((res) => {
            setIsModalOpen(false);
            props.onUpdate();
            message.success("Operação concluída!");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        message.error("As senhas precisam ser iguais!");
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {props.edit ? "Editar" : "Adicionar"}
      </Button>
      <Modal
        title={`${props.edit ? "Editar" : "Adicionar"} ${
          props.user?.name || ""
        }`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="userModal">
          <div>
            <Typography.Title level={5}>Nome</Typography.Title>
            <Input
              name="name"
              value={values.name}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
            />
          </div>

          <div>
            <Typography.Title level={5}>Store Id</Typography.Title>
            <Input
              name="store_id"
              value={values.store_id}
              onChange={(e) =>
                setValues({ ...values, store_id: e.target.value })
              }
            />
          </div>
          <div>
            <Typography.Title level={5}>Endereço</Typography.Title>
            <Input
              name="address"
              value={values.address}
              onChange={(e) =>
                setValues({ ...values, address: e.target.value })
              }
            />
          </div>
          <div>
            <Typography.Title level={5}>Usuário</Typography.Title>
            <Select
              name="personId"
              style={{ width: "100%" }}
              value={values.personId}
              onChange={(e) => setValues({ ...values, personId: e })}
              options={users.map((user) => {
                return { value: user.id, label: user.name };
              })}
            />
          </div>
          <div>
            <Typography.Title level={5}>Cliente</Typography.Title>
            <Select
              name="personId"
              style={{ width: "100%" }}
              value={values.clientId}
              onChange={(e) => setValues({ ...values, clientId: e })}
              options={clients.map((user) => {
                return { value: user.id, label: user.name };
              })}
            />
          </div>
          <div>
            <Typography.Title level={5}>Descrição</Typography.Title>
            <TextArea
              name="description"
              value={values.description}
              onChange={(e) =>
                setValues({ ...values, description: e.target.value })
              }
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UserModal;
