import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.css";
import { Button, Col, Modal, Row, Table } from "antd";
import * as links from "../../../utils/links";
import { AuthContext } from "../../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import LoadingAction from "../../../themes/LoadingAction/LoadingAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faCheckCircle,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import api from "../../../api";

const DashboardFornecedor = (props) => {
  const { setDataUser, loading, authInfo, setNotiMessage } =
    useContext(AuthContext);
  const { dataUser } = authInfo;
  let navigate = useNavigate();
  const token = authInfo?.dataUser?.token;
  const premiumExpiration = authInfo?.dataUser?.premiumExpiration ?? null;
  const hasData = !!authInfo?.dataUser?.hasData;
  const [favorites, setFavorites] = useState([]);
  const [meusFits, setMeusFits] = useState(null);
  const [totalCanais, setTotalCanais] = useState(null);
  const [totalFornecedores, setTotalFornecedores] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataCurrentDetail, setDataCurrentDetail] = useState(null);

  // useEffect(() => {
  //     dataData();
  // }, [])

  useEffect(() => {
    dataData();

    const intervalId = setInterval(() => {
      dataData();
    }, 60000);

    // Limpar o intervalo quando o componente for desmontado para evitar vazamento de memória
    return () => clearInterval(intervalId);
  }, []);

  const dataData = () => {
    setIsLoading(true);
    api
      .get(`/machine/client/consult/${dataUser.id}`)
      .then((res) => {
        setIsLoading(false);
        setTotalFornecedores(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleMaquinaClick = (id, nome, storeId, pulso) => {
    const maquinaInfos = { nome, storeId, pulso };
    navigate(`${links.FORNECEDOR_SEARCH_CANAIS}/${id}`, {
      state: maquinaInfos,
    });
  };

  return (
    <div className="Dashboard_container">
      {isLoading && <LoadingAction />}
      {/* <div className="WarningMsg">
                    {dataUser.warningMsg}
                </div> */}
      <div className="WarningMsgSpan">
        <span>{dataUser.warningMsg}</span>
      </div>
      <div className="Dashboard_staBlockTitle">Monitoramento</div>
      <Button style={{ marginLeft: "15px" }} onClick={dataData}>
        <FontAwesomeIcon icon={faArrowsRotate} style={{ marginRight: "5px" }} />
        Atualizar
      </Button>
      <Row>
        {totalFornecedores.map((post) => (
          <Col xs={24} md={24} lg={12} xl={8} className="Dashboard_col">
            <div
              className="maquina"
              key={post.id}
              onClick={() =>
                handleMaquinaClick(
                  post.id,
                  post.name,
                  post.store_id,
                  post.pulseValue
                )
              }
            >
              <div className="maquina-info">
                {(() => {
                  switch (post.status) {
                    case "ONLINE":
                      return (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          color={"green"}
                          className="logout-icon fa-3x"
                        />
                      );
                    case "OFFLINE":
                      return (
                        <FontAwesomeIcon
                          icon={faXmarkCircle}
                          color={"red"}
                          className="logout-icon fa-3x"
                        />
                      );
                    case "PAGAMENTO_RECENTE":
                      return (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          color={"blue"}
                          className="logout-icon fa-3x"
                        />
                      );
                    default:
                      return null;
                  }
                })()}
                <h2>{post.name}</h2>
                <h4 style={{ fontWeight: "300" }}>
                  {post.status} - {post.description}
                </h4>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default DashboardFornecedor;
