import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVIDOR,
  withCredentials: true,
  headers: {
    Authorization: `token=${Cookies.get("token")}`,
  },
});

axios.defaults.withCredentials = true;

export default api;
