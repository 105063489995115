import React, { useContext, useEffect, useState } from "react";
import { Button, DatePicker, Flex, Modal, message } from "antd";
import { AuthContext } from "../../contexts/AuthContext";
import api from "../../api";
const ReportModal = ({ isModalOpen, setIsModalOpen }) => {
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [totalFornecedores, setTotalFornecedores] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const { authInfo } = useContext(AuthContext);
  const { dataUser } = authInfo;
  const { RangePicker } = DatePicker;
  const [loadingTable, setLoadingTable] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dataData = () => {
    api
      .get(`/machine/client/consult/${dataUser.id}`)
      .then((res) => {
        setTotalFornecedores(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    dataData();
  }, [isModalOpen]);

  const getPaymentsReport = () => {
    if (dataInicio == null || dataFim == null) {
      message.error("Selecione as datas");
      return;
    }
    if (selectedIds.length === 0) {
      message.error("Selecione pelo menos uma maquina");
      return;
    }
    setLoadingTable(true);

    const url = `/payments/report/user`;
    api
      .post(url, {
        start: dataInicio,
        end: dataFim,
        machinesId: selectedIds,
      })
      .then((res) => {
        const nomeArquivo = res.data;
        setLoadingTable(false);
        window.open(
          `${process.env.REACT_APP_SERVIDOR}/file/${nomeArquivo}`,
          "_blank"
        );
      })
      .catch((err) => {
        setLoadingTable(false);
      });
  };

  return (
    <>
      <Modal
        title="Relatório de máquinas"
        centered
        open={isModalOpen}
        onOk={getPaymentsReport}
        onCancel={handleCancel}
      >
        <RangePicker
          showTime={{ format: "HH:mm" }}
          style={{
            border: "1px solid",
            borderRadius: "4px",
            marginBottom: "10px",
          }}
          placeholder={["Data Inicial", "Data Final"]}
          onChange={(dates, dateStrings) => {
            setDataInicio(dateStrings ? dateStrings[0] : null);
            setDataFim(dateStrings ? dateStrings[1] : null);
          }}
        />

        {totalFornecedores.map((item) => (
          <Flex
            key={item.id}
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              borderBottom: "1px solid",
              borderColor: "rgba(0, 0, 0, 0.2)",
            }}
          >
            <p>{item?.name}</p>
            <Button
              type="primary"
              onClick={() =>
                selectedIds.includes(item.id)
                  ? setSelectedIds(selectedIds.filter((id) => id !== item.id))
                  : setSelectedIds([...selectedIds, item.id])
              }
            >
              {selectedIds.includes(item.id) ? "Selecionado" : "Selecionar"}
            </Button>
          </Flex>
        ))}
      </Modal>
    </>
  );
};
export default ReportModal;
