import { useEffect, useState } from "react";
import "../Machine/machine.css";
import api from "../../../api";
import MachineModal from "./MachineModal";
import { Button, Col, Popconfirm, Row, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import * as links from "../../../utils/links";
import { useNavigate } from "react-router-dom";

const Machines = () => {
  const [machines, setMachines] = useState([]);
  const navigate = useNavigate();

  const handleGetMachines = () => {
    api
      .get("/machine/list")
      .then((res) => {
        setMachines(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetMachines();
  }, []);

  const confirm = (e) => {
    api
      .delete(`/machine/delete/${e}`)
      .then((res) => {
        message.success("Operação concluída!");
        handleGetMachines();
      })
      .catch((err) => {
        message.error("Ocorreu um erro ao excluir o item!");
      });
  };
  const cancel = (e) => {
    message.error("Operação cancelada!");
  };
  const handleMaquinaClick = (id, nome, storeId, pulso) => {
    const maquinaInfos = { nome, storeId, pulso };
    navigate(`${links.FORNECEDOR_SEARCH_CANAIS}/${id}`, {
      state: maquinaInfos,
    });
  };

  return (
    <div>
      <div className="userFlex">
        <div className="Dashboard_staBlockTitle">Máquinas</div>
        <MachineModal onUpdate={handleGetMachines} />
      </div>

      <div className="userboxContainer">
        {machines.map((post) => (
          <Row>
            <div className="maquina2" key={post.id}>
              <div className="maquina2-icon">
                <div className="maquina2-info">
                  <h5>{post.name}</h5>
                  <div>
                    <b>Status:</b> {post.status}
                  </div>
                  <div>
                    <b>Usuário:</b> {post.person.name}
                  </div>
                  <div>
                    <b>Cliente:</b> {post.client.name}
                  </div>
                  <div>
                    <b>Descrição:</b> {post.description}
                  </div>
                </div>
              </div>
              <div className="userboxButtons">
                <Button
                  onClick={() =>
                    handleMaquinaClick(
                      post.id,
                      post.name,
                      post.store_id,
                      post.pulseValue
                    )
                  }
                >
                  Acessar
                </Button>
                <MachineModal
                  machine={post}
                  edit
                  onUpdate={handleGetMachines}
                />
                <Popconfirm
                  title="Excluir máquina?"
                  description="Tem certeza que deseja excluir este item?"
                  onConfirm={() => confirm(post.id)}
                  onCancel={cancel}
                  placement="left"
                  okText="Sim"
                  cancelText="Não"
                >
                  <Button danger>Delete</Button>
                </Popconfirm>
              </div>
            </div>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default Machines;
