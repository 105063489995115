import React, { useCallback, useContext, useEffect, useState } from "react";
import LoadingAction from "../../../themes/LoadingAction/LoadingAction";
import "./PagamentosSearch.css";
import { Button, Col, Flex, Input, Row, Table, message } from "antd";
import { AuthContext } from "../../../contexts/AuthContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import _, { debounce } from "lodash";

import { useParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DatePicker } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import api from "../../../api";
import { DownloadOutlined } from "@ant-design/icons";

const PagamentosSearch = (props) => {
  const location = useLocation();
  const maquinaInfos = location.state;
  const { setDataUser, loading, authInfo, setNotiMessage } =
    useContext(AuthContext);
  let navigate = useNavigate();
  const token = Cookies.get("token");
  const [isLoading, setIsLoading] = useState(false);
  // const [searchText, setsearchText] = useState('');
  const [searchText, setSearchText] = useState("");
  const [listCanals, setListCanals] = useState([]);
  const [estornos, setEstornos] = useState("");
  const [total, setTotal] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [count, setCount] = useState(0);

  // const []
  const { id } = useParams();
  const { RangePicker } = DatePicker;

  useEffect(() => {
    getData(id);
  }, []);

  useEffect(() => {
    if (dataFim != null) {
      getPaymentsPeriod(dataInicio, dataFim);
    }
  }, [dataFim, dataInicio]);

  const getData = (id) => {
    if (id.trim() !== "") {
      setLoadingTable(true);
      api
        .post(`/payments/${id}`)
        .then((res) => {
          setLoadingTable(false);
          setEstornos(res.data.estornos);
          setCount(res.data.products);
          setTotal(res.data.total);
          setListCanals(res.data.pagamentos);
        })
        .catch((err) => {
          setLoadingTable(false);
        });
    }
  };

  const getPaymentsPeriod = (dataInicio, dataFim) => {
    if (id.trim() !== "") {
      setLoadingTable(true);
      const url = `/payments/${id}`;
      api
        .post(url, {
          start: dataInicio,
          end: dataFim,
        })
        .then((res) => {
          setLoadingTable(false);
          setEstornos(res.data.estornos);
          setCount(res.data.products);
          setTotal(res.data.total);
          setListCanals(res.data.pagamentos);
        })
        .catch((err) => {
          setLoadingTable(false);
        });
    }
  };

  const getPaymentsReport = (dataInicio, dataFim) => {
    if (dataInicio == null || dataFim == null) {
      message.error("Selecione as datas");
      return;
    }
    if (id.trim() !== "") {
      setLoadingTable(true);
      const url = `/payments/report/client/${id}`;
      api
        .post(url, {
          start: dataInicio,
          end: dataFim,
        })
        .then((res) => {
          const nomeArquivo = res.data;
          setLoadingTable(false);
          window.open(
            `${process.env.REACT_APP_SERVIDOR}/file/${nomeArquivo}`,
            "_blank"
          );
        })
        .catch((err) => {
          setLoadingTable(false);
        });
    }
  };

  const columns = [
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      width: 500,
      render: (data) => (
        <span>{moment(data).format("DD/MM/YYYY HH:mm:ss")}</span>
      ),
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value",
      render: (valor) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(valor),
    },
    {
      title: "Método de pagamento",
      dataIndex: "payment_type",
      key: "payment_type",
      render: (method) => method,
    },
    {
      title: "Identificador MP",
      dataIndex: "mercadoPagoId",
      key: "mercadoPagoId",
    },
    {
      title: "Estornado",
      dataIndex: "refunded",
      key: "refunded",
      width: 100,
      render: (estornado, record) =>
        estornado ? (
          <OverlayTrigger
            key={record.key}
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top-${record.key}`}>
                {record.motivoEstorno
                  ? record.motivoEstorno
                  : "Sem motivo registrado"}
              </Tooltip>
            }
          >
            <span style={{ color: "gray", cursor: "pointer" }}>
              {estornado ? "Estornado" : "Recebido"}
            </span>
          </OverlayTrigger>
        ) : (
          <span style={{ color: estornado ? "gray" : "green" }}>
            {estornado ? "Estornado" : "Recebido"}
          </span>
        ),
    },
  ];

  return (
    <div className="PagamentosSearch_container">
      {isLoading && <LoadingAction />}
      <Flex
        align="center"
        justify="between"
        style={{ width: "100%", justifyContent: "space-between" }}
      >
        <div className="Dashboard_staBlockTitle">{maquinaInfos.nome}</div>
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => getPaymentsReport(dataInicio, dataFim)}
        >
          Gerar relatório
        </Button>
      </Flex>
      <div className="PagamentosSearch_body">
        <div className="PagamentosSearch_content">
          <div className="PagamentosSearch_datePicker">
            <RangePicker
              showTime={{ format: "HH:mm" }}
              style={{
                border: "1px solid",
                borderRadius: "4px",
              }}
              placeholder={["Data Inicial", "Data Final"]}
              onChange={(dates, dateStrings) => {
                setDataInicio(dateStrings[0]);
                setDataFim(dateStrings[1]);
              }}
            />
          </div>
          <div
            className="PagamentosSearch_titleList"
            style={{ marginBottom: "10px" }}
          >
            <div style={{ marginLeft: "20px" }}>Total</div>
            <div className="PagamentosSearch_nbList">
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(total)}
            </div>
            <div style={{ marginLeft: "20px" }}>Estornos</div>
            <div className="PagamentosSearch_nbList">
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(estornos)}
            </div>
            <div style={{ marginLeft: "20px" }}>Saída de produtos</div>
            <div className="PagamentosSearch_nbList">{count}</div>
            <div style={{ marginLeft: "20px" }}>Pulso</div>
            <div className="PagamentosSearch_nbList">
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(maquinaInfos.pulso)}
            </div>
            <div style={{ marginLeft: "20px" }}>Store ID</div>
            <div className="PagamentosSearch_nbList">
              {maquinaInfos.storeId}
            </div>
          </div>
          <Table
            columns={columns}
            style={{ overflowX: "auto" }}
            dataSource={listCanals}
            pagination={false}
            loading={loadingTable}
            locale={{
              emptyText:
                searchText.trim() !== "" ? (
                  "-"
                ) : (
                  <div>Não foram encontrados resultados para sua pesquisa.</div>
                ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PagamentosSearch;
