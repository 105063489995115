import React, { useContext, useState } from "react";
import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  HddOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";

import { AuthContext } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import * as links from "../../../utils/links";
import ReportModal from "../../ReportModal";
import { Header } from "antd/es/layout/layout";

const { Content, Footer, Sider } = Layout;

const App = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { children } = props;
  const { setDataUser, authInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const { dataUser } = authInfo;
  const [keyValue, setKeyValue] = useState("1");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const logout = () => {
    setDataUser(null);
    navigate("/");
    Cookies.remove("token");
  };

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
  const itemsPerson = [
    getItem("Máquinas", "1", <AppstoreOutlined />),
    getItem("Cadastro de clientes", "4", <UsergroupAddOutlined />),
    getItem("Relatorio de Máquinas", "6", <HddOutlined />),
  ];

  const itemsAdmin = [
    getItem("Máquinas", "1", <AppstoreOutlined />),
    getItem("Usuários", "2", <UserAddOutlined />),
    getItem("Gerenciar Máquinas", "3", <AppstoreAddOutlined />),
    getItem("Clientes", "4", <UsergroupAddOutlined />),
    getItem("Relatorio de Máquinas", "6", <HddOutlined />),
  ];

  const itemsClient = [getItem("Máquinas", "1", <AppstoreOutlined />)];

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <ReportModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <Sider
        collapsible
        trigger={null}
        collapsed={collapsed}
        breakpoint="md"
        onCollapse={() => setCollapsed(!collapsed)}
        collapsedWidth={window.innerWidth < 768 ? 0 : 80}
      >
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          items={
            dataUser.isAdmin
              ? itemsAdmin
              : dataUser.type === "person"
              ? itemsPerson
              : itemsClient
          }
          onClick={({ key, domEvent }) => {
            if (key === "1") {
              navigate(links.DASHBOARD_FORNECEDOR);
              setCollapsed(false);
            } else if (key === "2") {
              navigate(links.ADD_USER);
              setCollapsed(false);
            } else if (key === "3") {
              navigate(links.ADD_MACHINE);
              setCollapsed(false);
            } else if (key === "4") {
              navigate(links.ADD_CLIENT);
              setCollapsed(false);
            } else if (key === "6") {
              setIsModalOpen(!isModalOpen);
              setCollapsed(false);
            } else {
              logout();
            }
          }}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: "white",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid gainsboro",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "20px",
              width: 50,
              height: 50,
            }}
          />

          <Button
            onClick={logout}
            type="primary"
            icon={<LogoutOutlined />}
            style={{ marginRight: 10 }}
          >
            Sair
          </Button>
        </Header>
        <Content>
          <div
            style={{
              padding: 24,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              height: "100%",
            }}
          >
            {children}
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Slot Pay ©{new Date().getFullYear()} All Rights Reserved
        </Footer>
      </Layout>
    </Layout>
  );
};
export default App;
