import "./App.css";
import PrivateRoute from "./routes/PrivateRoute";
import { Route, Routes, useNavigate } from "react-router-dom";
import NotFound from "./components/NotFound/NotFound";
import SignIn from "./components/Auth/SignIn/SignIn";
import PublicRoute from "./routes/PublicRoute";
import Main from "./components/Layout/Main/Main";
import * as links from "./utils/links";
import DashboardMaquinas from "./components/Main/Dashboard/DashboardMáquinas";
import PagamentosSearch from "./components/Main/SearchPagamentos/PagamentosSearch";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "./contexts/AuthContext";
import Users from "./components/Main/Users";
import Machines from "./components/Main/Machine";
import AdminRoute from "./routes/AdminRoutes";
import Client from "./components/Main/Client";
import PersonRoute from "./routes/PersonRoutes";

function App() {
  const {
    setDataUser,
    loading,
    notiMessage,
    setNotiMessage,
    notiMessageInfo,
    setNotiMessageInfo,
    authInfo,
  } = useContext(AuthContext);

  const type = authInfo?.dataUser?.type;

  useEffect(() => {
    if (notiMessage) {
      setNotiMessage(null);

      switch (notiMessage.type) {
        case "error":
          NotificationManager.error(notiMessage.message, "Hmm... ");
          break;
        case "success":
          NotificationManager.success(notiMessage.message, "... ");
          break;
        case "info":
          NotificationManager.info(notiMessage.message, "... ");
          break;
        case "warning":
          NotificationManager.warning(notiMessage.message, "... ");
          break;
      }
    }
  }, [notiMessage]);

  // useEffect(() => {
  //     if (notiMessageInfo) {
  //         setNotiMessageInfo(null)
  //         NotificationManager.info(notiMessageInfo, '... ');
  //     }
  // }, [notiMessageInfo])

  return (
    <>
      <NotificationContainer />
      <Routes>
        {
          <>
            <Route
              path={`${links.FORNECEDOR_SEARCH_CANAIS}/:id`}
              element={
                <PrivateRoute>
                  <Main>
                    <PagamentosSearch />
                  </Main>
                </PrivateRoute>
              }
            />
            <Route
              path={links.DASHBOARD_FORNECEDOR}
              element={
                <PrivateRoute>
                  <Main>
                    <DashboardMaquinas></DashboardMaquinas>
                  </Main>
                </PrivateRoute>
              }
            />
            <Route
              path={links.ADD_USER}
              element={
                <AdminRoute>
                  <Main>
                    <Users />
                  </Main>
                </AdminRoute>
              }
            />
            <Route
              path={links.ADD_MACHINE}
              element={
                <AdminRoute>
                  <Main>
                    <Machines />
                  </Main>
                </AdminRoute>
              }
            />
            <Route
              path={links.ADD_CLIENT}
              element={
                <PersonRoute>
                  <Main>
                    <Client />
                  </Main>
                </PersonRoute>
              }
            />
          </>
        }
        <Route
          path={links.SIGNIN}
          element={
            <PublicRoute>
              <SignIn />
            </PublicRoute>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
//...
